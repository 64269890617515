import { App } from '@/components/App'
import { createRootRouteWithContext } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'

type RouterContext = {
    userId?: string | null,
    isAuthInitiating: boolean,
}

export const Route = createRootRouteWithContext<RouterContext>()({
    component: () => <>
        <App/>
        {import.meta.env.DEV && <TanStackRouterDevtools />}
    </>,
})