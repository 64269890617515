import { Button } from "@/components/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { useTranslation } from 'react-i18next'
import { useUser } from '@/state/user'
import { Switch } from '../ui/switch'
import { useState } from 'react'
import './styles.css'

const monthlyPrice = 10
const yearlyPrice = 8

const subscribeLink = (userId: string, email: string, yearly: boolean) => (yearly ? import.meta.env.VITE_STRIPE_BILLING_LINK_PERSONAL_YEARLY :import.meta.env.VITE_STRIPE_BILLING_LINK_PERSONAL_MONTHLY) + `?prefilled_email=${email}&client_reference_id=${userId}`

export const Subscribe = () => {
    const { t } = useTranslation()
    const profile = useUser(({profile}) => profile)
    const logout = useUser(({logout}) => logout)

    const [isYearly, setIsYearly] = useState(true)

    console.log('hi')

    if (!profile) return null

    return <div className="bg-background w-full h-screen flex items-center justify-center px-4">
        <Card className="w-full max-w-sm">
            <CardHeader className="text-center">
                <CardTitle className="text-2xl">{t('subscribe-title')}</CardTitle>
                <CardDescription className="text-semibold">{t('subscribe-description')}</CardDescription>
            </CardHeader>
            <CardContent className="grid gap-6 py-10">
                <div className="flex gap-4 justify-center">
                    <div className="text-base cursor-pointer" onClick={() => setIsYearly(false)}>{t('monthly')}</div>
                    <Switch checked={isYearly} onCheckedChange={(e) => setIsYearly(e)}/>
                    <div className="text-base cursor-pointer" onClick={() => setIsYearly(true)}>{t('yearly')}</div>
                </div>
                <div className="flex justify-center gap-2 items-end text-6xl font-semibold">
                    <span>{isYearly ? yearlyPrice : monthlyPrice}€</span>
                    <span className="text-sm opacity-50">{t('per-month')}</span>
                </div>
                <div className="text-center opacity-50">
                    {isYearly ? t('billed-annually',  { price: yearlyPrice * 12 }) : t('billed-monthly')}
                </div>
            </CardContent>
            <CardFooter className="flex-col">
                <button  className="uiverse" onClick={handleSubscribeClick}>
                    <div className="wrapper px-8 py-3">
                        <span>{t('subscribe-button')}</span>
                        <div className="circle circle-12"></div>
                        <div className="circle circle-11"></div>
                        <div className="circle circle-10"></div>
                        <div className="circle circle-9"></div>
                        <div className="circle circle-8"></div>
                        <div className="circle circle-7"></div>
                        <div className="circle circle-6"></div>
                        <div className="circle circle-5"></div>
                        <div className="circle circle-4"></div>
                        <div className="circle circle-3"></div>
                        <div className="circle circle-2"></div>
                        <div className="circle circle-1"></div>
                    </div>
                </button>
                <Button className="mt-8" variant="secondary" onClick={logout}>{t('logout')}</Button>
            </CardFooter>
        </Card>
    </div>

    function handleSubscribeClick() {
        if (!profile) return
        window.location.href = subscribeLink(profile.id, profile.email, isYearly)
    }
}
