import { Loader } from '@/components/App/Loader'
import { useUser } from '@/state/user'
import { createFileRoute, Navigate, Outlet } from '@tanstack/react-router'

const Component = () => {
    const isInitiating = useUser(({ isInitiating }) => isInitiating)
    const userId = useUser(({ user }) => user?.id)
    const hasAccess = useUser(({ profile }) => profile?.has_access)
    
    if (isInitiating) {
        return <Loader/>
    }

    if (!userId) {
        return <Navigate to="/login"/>
    }

    return <>
        {!hasAccess && <Navigate to="/subscribe"/>}
        <Outlet/>
    </>
}

export const Route = createFileRoute('/_authenticated')({
    component: Component,
})
