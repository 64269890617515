import { Card } from '@/components/ui/card'
import { createFileRoute, Outlet } from '@tanstack/react-router'

const AuthContainer = () => {
    return <div className="bg-[url(/hero-bg.webp)] bg-center bg-no-repeat bg-cover w-full h-screen flex flex-col items-center justify-center px-4">
        <a href="https://kanman.de" className="mb-8 block flex items-center font-semibold px-4 py-2 shadow-xl w-[fit-content] cursor-pointer gap-2 rounded-3xl border-0 bg-[linear-gradient(15deg,#190088,#2c3f68,#091720,#3f3c18,#ffe678)] bg-no-repeat text-[#fffab5] hover:text-white">
            <img src="/logo-k.png" className="h-4"/>
            kanman
        </a>
        <Card className="w-full max-w-sm shadow-2xl pt-4">
            <Outlet/>
        </Card>
    </div>
}

export const Route = createFileRoute('/_auth')({
  component: AuthContainer,
})
