import { useBoardsStore } from '@/state/boards'
import { createFileRoute } from '@tanstack/react-router'
import { Navigate } from '@tanstack/react-router'

const Homepage = () => {
    const boards = useBoardsStore(({ boards }) => boards)

    if (!boards.length) {
        return <></>
    }

    return <Navigate to="/boards/$boardId" params={{ boardId: boards[0].id }} />
}

export const Route = createFileRoute('/_authenticated/')({
    component: () => <Homepage />,
})
