import CountUp from 'react-countup'
import { useProjectsStore, type Project } from '@/state/projects'
import {
    Card,
    CardHeader,
    CardTitle,
  } from '@/components/ui/card'
import { useProjectForm } from '@/hooks/useProjectForm'
import { Edit } from 'lucide-react'
import { Button } from '../ui/button'

export const ProjectCard = ({project}: {project: Project}) => {
    const focusedProjectId = useProjectsStore(({focusedProjectId}) => focusedProjectId)
    const focusProject = useProjectsStore(({focusProject}) => focusProject)
    const clearFocus = useProjectsStore(({clearFocus}) => clearFocus)

    const progressStyle = 'h-2 w-4 rounded-full mr-1'
    const openStyle = progressStyle + ' bg-gradient-to-r dark:from-gray-600 dark:to-gray-800 from-gray-300 to-gray-400'
    const inProgressStyle = progressStyle + ' bg-gradient-to-r dark:from-yellow-500 dark:to-yellow-600 from-yellow-400 to-yellow-500'
    const doneStyle = progressStyle + ' bg-gradient-to-r dark:from-green-500 dark:to-green-600 from-green-400 to-green-500'
    const emptyStyle = progressStyle + ' border-2 border-gray-700'

    let focusStyle = ''
    if (focusedProjectId) {
        focusStyle = 'mb-0 '
        if (focusedProjectId === project.id) {
            focusStyle += 'bg-accent/50 dark:bg-gray-800/50 z-50 relative '
        } else if (focusedProjectId) {
            focusStyle += 'opacity-0 scale-90 pointer-events-none '
        }
    } else {
        focusStyle = 'mb-2 md:mb-3'
    }
    

    return <Card 
        className={'border-slate-600/50 dark:border-slate-200/20 border-2 flex flex-col relative group transition-all ease-in-out duration-100 bg-background ' + focusStyle}
        onClick={handleClick}>
        <div className="card-content">
            <CardHeader className="p-3 !pb-0 md:p-3">
                <CardTitle className="text-base md:text-lg flex items-center justify-between">
                    {project.label}
                    <div className="flex-1"/>
                    <div className="transition-opacity md:opacity-0 md:group-hover:opacity-100 flex items-center gap-3">
                        <EditProjectButton project={project} />
                    </div>
                    <div className="text-sm text-gray-500 dark:text-gray-400/30 ml-3 w-8 text-right whitespace-nowrap">
                        <CountUp 
                            suffix=" %"
                            duration={1}
                            end={Math.round(project.progress * 100)}/>
                    </div>
                </CardTitle>
            </CardHeader>
            <ul className="flex m-3 !mt-1.5">
                {Array(project.num_tasks_open).fill(0).map((_, i) => <li key={i} className={openStyle}></li>)}
                {Array(project.num_tasks_in_progress).fill(0).map((_, i) => <li key={i} className={inProgressStyle}></li>)}
                {Array(project.num_tasks_done).fill(0).map((_, i) => <li key={i} className={doneStyle}></li>)}
                {!project.num_tasks_total && <li className={emptyStyle}></li>}
            </ul>
        </div>
    </Card>

    function handleClick() {
        if (focusedProjectId === project.id) {
            clearFocus()
            return
        }

        focusProject(project.id)
    }
}

const EditProjectButton = ({ project }: { project: Project }) => {
    const openProjectForm = useProjectForm(({openProjectForm}) => openProjectForm)

    return <Button 
        variant="ghost" 
        className="p-0 h-0 opacity-50 hover:opacity-100"
        onClick={handleClick}>
        <Edit className="h-4 w-4" />
    </Button>

    function handleClick (e: React.MouseEvent) {
        e.stopPropagation()
        openProjectForm(project.id)
    }
}