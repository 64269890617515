import { Login } from '@/components/Login'
import { createFileRoute } from '@tanstack/react-router'
import { Suspense } from 'react'

/*const LoginForm = () => {
    return <CardContent className="grid gap-4">
    </CardContent>
}*/

export const Route = createFileRoute('/_auth/login')({
  component: () => (
    <Suspense fallback={<div>Loading...</div>}>
      <Login />
    </Suspense>
  ),
})
