import { useUser } from '@/state/user'
import { RouterProvider } from '@tanstack/react-router'
import { createRouter } from '@tanstack/react-router'
import { routeTree } from '@/routeTree.gen'

export const router = createRouter({ routeTree, context: { 
    userId: null, 
    isAuthInitiating: true,
} })

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

export const AuthShell = () => {
    const userId = useUser(({user}) => user?.id)
    const isAuthInitiating = useUser(({isInitiating}) => isInitiating)

    return <RouterProvider router={router} context={{ userId, isAuthInitiating }} />
}