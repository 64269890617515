import { BoardList } from './BoardList'
import { UserMenu } from './UserMenu'
import { NewProjectButton } from './NewProjectButton'

export const Header = () => {    
    return <header className="relative my-0 mx-3 md:mx-4 z-20 flex justify-between gap-2 md:gap-4 items-center pt-2 pb-4 md:pt-6 md:pb-6 px-2 md:px-0">
        <BoardList/>
        <div className="flex-1"/>
        <NewProjectButton/>
        <UserMenu/>
        <div className="absolute inset-0 z-[-1] bg-gradient-to-b from-20% from-[hsl(var(--background))]"/>
    </header>
}