import { CardContent } from "@/components/ui/card"
import { db } from '@/services/api'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useUser } from '@/state/user'
import { Navigate } from '@tanstack/react-router'

export const Login = () => {
    const { t } = useTranslation()
    const userId = useUser(({ user }) => user?.id)
    const localization = useMemo(() => {
        const common = {
            email_label: t('email'),
            email_input_placeholder: t('email-placeholder'),
            password_label: t('password'),
            password_input_placeholder: t('password-placeholder'),
        }
        return {
            variables: {
                sign_in: {
                    ...common,
                    button_label: t('login-button'),
                    loading_button_label: t('login-button-loading'),
                    link_text: t('already-registered'),
                },
                sign_up: {
                    ...common,
                    button_label: t('register-button'),
                    loading_button_label: t('register-button-loading'),
                    link_text: t('register'),
                },
                forgotten_password: {
                    ...common,
                    link_text: t('forgot-password'),
                    button_label: t('reset-password-button'),
                    loading_button_label: t('reset-password-button-loading'),
                },
            }
        }
    }, [t])

    if (userId) {
        // Send the user back to the homepage if they are already logged in
        return <Navigate to="/"/>
    }

    return <CardContent className="grid gap-4">
        <Auth
            theme="dark"
            providers={[]}
            supabaseClient={db}
            appearance={{ theme: ThemeSupa, className: {
                button: '!bg-yellow-300 !font-bold !text-black !border-0 hover:!bg-yellow-500',
            } }}
            localization={localization}/>
    </CardContent>
}
