import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'
import { ResponsiveDrawer } from '../ResponsiveDrawer'
import { Board, useBoardsStore } from '@/state/boards'
import { useEffect, useState } from 'react'
import { useBoardForm } from '@/hooks/useBoardForm'
import { DeleteButton } from '../DeleteButton'
import { useTranslation } from 'react-i18next'

export const BoardForm = () => {
    const { t } = useTranslation()
    const openedBoardId = useBoardForm(({ openedBoardId }) => openedBoardId)
    const closeBoardForm = useBoardForm(({ closeBoardForm }) => closeBoardForm)
    const updateBoard = useBoardsStore(({ updateBoard }) => updateBoard)
    const createBoard = useBoardsStore(({ createBoard }) => createBoard)

    const board = {
        ...useBoardsStore(({ boards }) => boards.find(board => board.id === openedBoardId)),
        ...useBoardForm(({ overload }) => overload),
    }

    const [label, setLabel] = useState(board?.label || '')

    useEffect(() => setLabel(board?.label || ''), [board?.label])

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!openedBoardId) return

        const newState = { label }
        
        if (openedBoardId === 'new') {
            await createBoard(newState)
        } else {
            await updateBoard(openedBoardId, { ...board, ...newState })
        }

        closeBoardForm()
    }

    return <ResponsiveDrawer 
        open={!!openedBoardId}
        onClose={closeBoardForm}
        title={board?.id ? t('edit-board-title') : t('create-board-title')}
        description={board?.id ? t('edit-board-description') : t('create-board-description')}>
        <form className="grid items-start gap-4" onSubmit={handleSubmit}>
            <div className='grid gap-2'>
                <Label htmlFor="label">{t('label')}</Label>
                <Input 
                    type="text" 
                    id="label"
                    value={label} 
                    onChange={(e) => setLabel(e.currentTarget.value)} />
            </div>
            <Button type="submit">
                {board?.id ? t('edit-board-submit') : t('create-board-submit')}
            </Button>
            <div>
                <DeleteBoardButton board={board} />
            </div>
        </form>
    </ResponsiveDrawer>
}

const DeleteBoardButton = ({ board }: { board: Partial<Board> }) => {
    const deleteBoard = useBoardsStore(({ deleteBoard }) => deleteBoard)
    const closeBoardForm = useBoardForm(({ closeBoardForm }) => closeBoardForm)
    const { t } = useTranslation()

    const handleDelete = async () => {
        if (!board?.id) return
        await deleteBoard(board.id)
        closeBoardForm()
    }

    if (!board?.id) return null

    return <DeleteButton onDelete={handleDelete} label={t('delete-board-button')} />
}