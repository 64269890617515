import { Loader } from '@/components/App/Loader'
import { ProjectsEmptyState } from '@/components/ProjectsEmptyState'
import { ProjectsList } from '@/components/ProjectsList'
import { TasksEmptyState } from '@/components/TasksEmptyState'
import { TasksList } from '@/components/TasksList'
import { Toaster } from '@/components/ui/sonner'
import { useBoardsStore } from '@/state/boards'
import { useProjectsStore } from '@/state/projects'
import { useTasksStore } from '@/state/tasks'
import { createFileRoute } from '@tanstack/react-router'
import { useEffect } from 'react'

const BoardView = () => {
  const isProjectsStoreLoading = useProjectsStore(({ isLoading }) => isLoading)
  const isBoardsStoreLoading = useBoardsStore(({ isLoading }) => isLoading)
  const isTasksStoreLoading = useTasksStore(({ isLoading }) => isLoading)
  const isLoading =
    isBoardsStoreLoading || isTasksStoreLoading || isProjectsStoreLoading
  const selectedBoardId = useBoardsStore(
    ({ selectedBoardId }) => selectedBoardId,
  )
  const { boardId } = Route.useParams()

  useEffect(() => {
    if (selectedBoardId !== boardId) {
      // Make sure to update the stores based on the actual route params.
      // @todo this is a bit hacky, as it combines the old state handling and new routing system.
      // We need to refactor the stores to be more route-aware.
      useBoardsStore.getState().selectBoard(boardId)
    }
  }, [selectedBoardId, boardId])

  if (!selectedBoardId) {
    return null
  }

  if (isLoading) return <Loader />

  return (
    <div className="px-1 relative flex-1 grow w-screen max-w-[800px] mx-auto">
      <ProjectsList />
      <TasksList />
      <ProjectsEmptyState />
      <TasksEmptyState />
      <Toaster position="bottom-center" richColors closeButton />
    </div>
  )
}

export const Route = createFileRoute('/_authenticated/boards/$boardId')({
  component: BoardView,
})
